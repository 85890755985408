import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Missions from "./missions"

const App = () => {
  return (
    <Router>
      <Missions path="/app/missions/" />
      <Missions path="/app/missions/:missionId" />
    </Router>
  )
}
export default App